<template>
  <span class="add-author-modal" v-if="hasPerm('people.view_individual')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Ajouter"
      :ok-disabled="formInvalid"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-id-card"></i> Ajouter un auteur</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="individual-group"
            label="Membre"
            label-for="individual"
          >
            <individual-typeahead id="individual" @change="onIndividualChanged($event.individual)">
            </individual-typeahead>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import IndividualTypeahead from '@/components/Individual/IndividualTypeahead'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'AddPublicationAuthor',
  mixins: [BackendMixin],
  components: {
    IndividualTypeahead,
  },
  props: {
    modalId: {
      type: String,
      default: 'bv-modal-add-publication-author',
    },
  },
  data() {
    return {
      errorText: '',
      individual: null,
    }
  },
  computed: {
    formInvalid() {
      return this.individual === null
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    onIndividualChanged(individual) {
      this.individual = individual
    },
    async onSave() {
      this.$nextTick(() => {
        this.$bvModal.hide(this.modalId)
      })
      if (this.individual) {
        this.$emit('done', { individual: this.individual, })
      }
    },
  },
}
</script>

<style scoped>

</style>
