<template>
  <div class="publications">
    <page-header :title="title" icon="fa fa-book-bookmark" :links="links"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-row>
      <b-col>
        <tabs-bar
          enabled
          :tabs="tabs"
          :active="activeTab"
          @change="onTabChanged($event)"
        >
        </tabs-bar>
      </b-col>
    </b-row>
    <div ref="printMe">
      <div v-if="activeTab && activeTab.selector === 'detail'" class="tab-content">
        <b-row>
          <b-col cols="2">
            <b-form-group label-for="publicationType" label="Type">
              <b-select
                v-model="publicationType"
                :disabled="!editMode || (publicationTypes.length === 1)"
              >
                <b-select-option v-for="elt of publicationTypes" :key="elt.id" :value="elt">
                  {{ elt.name }}
                </b-select-option>
              </b-select>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="publicationNumber" label="Numéro">
              <b-input
                id="publicationNumber"
                v-model="publicationNumber"
                type="number" step="1" min="0"
                :disabled="!editMode"
              >
              </b-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="publicationTitle" label="Titre">
              <b-input id="publicationTitle" v-model="publicationTitle" type="text" :disabled="!editMode">
              </b-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="pagesCount" label="Nb de pages">
              <number-input v-model="pagesCount" step="1" :disabled="!editMode"></number-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="publishedOn" label="Date de publication">
              <b-input id="publishedOn" v-model="publishedOn" type="date" :disabled="!editMode">
              </b-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-form-group label-for="price" label="Prix">
              <decimal-input v-model="price" :disabled="!editMode"></decimal-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="sendingPrice" label="Prix d'envoi">
              <decimal-input v-model="sendingPrice" :disabled="!editMode"></decimal-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="fabPrice" label="Prix de revient">
              <decimal-input v-model="fabPrice" :disabled="!editMode"></decimal-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="authorPrice" label="Prix auteur">
              <decimal-input v-model="authorPrice"  id="authorPrice" :disabled="!editMode"></decimal-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="depositPrice" label="Prix déposant">
              <decimal-input v-model="depositPrice" id="depositPrice" :disabled="!editMode"></decimal-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label-for="isAvailable" label="Disponible">
              <b-form-checkbox v-model="isAvailable" :disabled="!editMode"></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="notes" label="Notes">
              <b-textarea id="notes" v-model="notes" :disabled="!editMode">
              </b-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <div v-if="hasAuthors">
          <b-col>
            <b-form-group
              label-for="authors"
              :label="(authors.length > 1) ? 'Auteurs' : 'Auteur'"
            >
              <div v-if="!editMode && authors.length === 0" class="empty-text">
                aucun
              </div>
              <b-row
                v-for="author of authors"
                :key="author.id"
              >
                <b-col cols="10">
                  <b-input
                    :value="author.individual.firstAndLastName()"
                    type="text"
                    disabled
                  >
                  </b-input>
                </b-col>
                <b-col cols="2" class="small-text">
                  <a href @click.prevent="deleteAuthor(author)" v-if="editMode">
                    <i class="fa fa-trash"></i>
                  </a>
                </b-col>
              </b-row>
              <div class="small-text" v-if="editMode">
                <a href @click.prevent="addAuthor()">
                  <i class="fa fa-plus"></i> Ajouter
                </a>
              </div>
            </b-form-group>
          </b-col>
        </div>
        <div v-if="hasArticles">
          <div class="articles-header">
            <b-row>
              <b-col>
                <b><counter-label :counter="articles.length" label="article"></counter-label></b>
                &nbsp;
                <a href @click.prevent="addArticle()" class="btn btn-secondary" v-if="editMode">
                  <i class="fa fa-plus"></i> Ajouter
                </a>
              </b-col>
              <b-col cols="6"></b-col>
            </b-row>
          </div>
          <div v-for="article of articles" :key="article.id" class="article-line">
            <b-row>
              <b-col cols="6">
                <b-form-group :label-for="'article' + article.id" label="Titre">
                  <b-input :id="'article' + article.id" v-model="article.title" type="text" :disabled="!editMode">
                  </b-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label-for="'author' + article.id"
                  :label="(article.authors.length > 1) ? 'Auteurs' : 'Auteur'"
                >
                  <div v-if="!editMode && article.authors.length === 0" class="empty-text">
                    aucun
                  </div>
                  <b-row
                    v-for="author of article.authors"
                    :key="author.id"
                  >
                    <b-col cols="10">
                      <b-input
                        :value="author.individual.firstAndLastName()"
                        type="text"
                        disabled
                      >
                      </b-input>
                    </b-col>
                    <b-col cols="2" class="small-text">
                      <a href @click.prevent="deleteArticleAuthor(article, author)" v-if="editMode">
                        <i class="fa fa-trash"></i>
                      </a>
                    </b-col>
                  </b-row>
                  <div class="small-text" v-if="editMode">
                    <a href @click.prevent="addArticleAuthor(article)">
                      <i class="fa fa-plus"></i> Ajouter
                    </a>
                  </div>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label-for="'keywords' + article.id" label="Mots-clef">
                  <div v-if="!editMode && article.keywords.length === 0" class="empty-text">
                    aucun
                  </div>
                  <b-row v-for="keyword of article.keywords" :key="keyword.id">
                    <b-col cols="10">
                      <b-input v-model="keyword.keyword" type="text" :disabled="!editMode">
                      </b-input>
                    </b-col>
                    <b-col cols="2" class="small-text" v-if="editMode">
                      <a href @click.prevent="deleteArticleKeyword(article, keyword)">
                        <i class="fa fa-trash"></i>
                      </a>
                    </b-col>
                  </b-row>
                  <div class="small-text" v-if="editMode">
                    <a href @click.prevent="addArticleKeyword(article)">
                      <i class="fa fa-plus"></i> Ajouter
                    </a>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group :label-for="'article-notes' + article.id" label="Notes">
                  <b-textarea :id="'article-notes' + article.id" v-model="article.notes" :disabled="!editMode">
                  </b-textarea>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label-for="'article-comments' + article.id" label="Commentaires">
                  <b-textarea :id="'article-comments' + article.id" v-model="article.comments" :disabled="!editMode">
                  </b-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right" v-if="editMode">
                <a href @click.prevent="deleteArticle(article)" class="btn btn-secondary">
                  <i class="fa fa-trash"></i> Supprimer l'article
                </a>
              </b-col>
            </b-row>
          </div>
        </div>
        <b-row v-if="canEdit">
          <b-col class="text-right">
            <a href @click.prevent="onEdit()" class="btn btn-secondary" v-if="!editMode">
              <i class="fa fa-edit"></i> Modifier
            </a>
            <a href @click.prevent="onCancel()" class="btn btn-secondary" v-if="editMode">
              <i class="fa fa-close"></i> Annuler
            </a>
            &nbsp;
            <a href @click.prevent="onSave()" class="btn btn-primary" v-if="editMode" :class="{ disabled: isSaveDisabled }">
              <i class="fa fa-save"></i> Enregistrer
            </a>
          </b-col>
        </b-row>
      </div>
      <div v-if="activeTab && activeTab.selector === 'subscriptors'" class="tab-content">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div v-if="!isLoading(loadingName)">
          <b>{{ publication.fullName() }}</b>
          <div v-if="copies.length === 0" class="empty-text">
            Aucun abonné
          </div>
          <div v-else>
            <x-table
              class="small"
              id="subscriptors"
              :columns="subscriptorColumns"
              :items="copyItems"
              :show-footer="true"
              :show-counter="true"
              verbose-name="abonné"
            ></x-table>
          </div>
        </div>
      </div>
      <div v-if="activeTab && activeTab.selector === 'copies'" class="tab-content">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div v-if="!isLoading(loadingName)">
          <b>{{ publication.fullName() }}</b>
          <div v-if="copies.length === 0" class="empty-text">
            Aucun exemplaire
          </div>
          <div v-else>
            <x-table
              id="copies"
              class="small"
              :columns="copyColumns"
              :items="copyItems"
              :show-footer="true"
              :show-counter="true"
              verbose-name="exemplaire"
            ></x-table>
          </div>
        </div>
      </div>
    </div>
    <add-publication-author
      modal-id="bv-modal-add-author"
      @done="onAuthorAdded($event)">
    </add-publication-author>
    <add-publication-copy-modal
      modal-id="bv-add-publication-copy"
      :publication="publication"
      v-if="publication && hasPerm('publications.add_publicationcopy')"
      @done="loadCopies(0)"
    ></add-publication-copy-modal>
    <confirm-modal
      name="delete-publication-copy"
      icon="fa fa-trash"
      :object="selectedCopy"
      title="Suppression d'un exemplaire"
      :text="deleteCopyText"
      @confirmed="onConfirmDelete"
    ></confirm-modal>
    <payment-modal
      modal-id="pay-copy-modal"
      :entity="paymentEntity"
      :invoice="paymentInvoice"
      @paid="loadCopies()"
    >
    </payment-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import router from '@/router'
import store from '@/store'
import { mapActions, mapMutations } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput.vue'
import NumberInput from '@/components/Controls/NumberInput.vue'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import CounterLabel from '@/components/Controls/CounterLabel'
import TabsBar from '@/components/Controls/TabsBar.vue'
import XTable from '@/components/Controls/Table/Table.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import AddPublicationAuthor from '@/components/Publications/AddPublicationAuthor.vue'
import AddPublicationCopyModal from '@/components/Publications/AddPublicationCopyModal.vue'
import PaymentModal from '@/components/Payments/PaymentModal.vue'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi, openDocument } from '@/utils/http'
import { makePublication, makePublicationType, makePublicationArticle, makePublicationCopy } from '@/types/publications'
import { TabItem } from '@/types/tabs'
import { slugify } from '@/utils/strings'

export default {
  name: 'publications-edit',
  mixins: [BackendMixin],
  components: {
    PaymentModal,
    ConfirmModal,
    AddPublicationCopyModal,
    XTable,
    TabsBar,
    NumberInput,
    DecimalInput,
    AddPublicationAuthor,
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  props: {
    publicationId: [String, Number],
  },
  data() {
    return {
      loadingName: 'publications-edit',
      publicationType: null,
      publicationTypes: [],
      publishedOn: '',
      publicationTitle: '',
      publicationNumber: null,
      articles: [],
      publication: null,
      newArticlesCounter: 0,
      newKeywordsCounter: 0,
      newAuthorsCounter: 0,
      selectedArticle: null,
      price: null,
      sendingPrice: null,
      fabPrice: null,
      authorPrice: null,
      depositPrice: null,
      isAvailable: false,
      pagesCount: 0,
      notes: '',
      authors: [],
      editMode: false,
      activeTab: null,
      copies: [],
      copyColumns: [],
      subscriptorColumns: [],
      selectedCopy: null,
      paymentEntity: null,
      paymentInvoice: null,
    }
  },
  watch: {
    publicationId: function() {
      this.loadPublication()
    },
    publicationType: function() {
      if (this.publicationType && !this.publicationId) {
        this.publicationNumber = this.publicationType.next
        this.price = this.publicationType.price
        this.depositPrice = Math.round(this.publicationType.price * this.publicationType.depositPriceRate * 100) / 100
        this.sendingPrice = this.publicationType.sendingPrice
        this.fabPrice = this.publicationType.fabPrice
      }
    },
  },
  computed: {
    title() {
      return 'Publication ' + store.getters.config.publicationTitle
    },
    tabs() {
      const tabs = [
        new TabItem('detail', 'Fiche', 'fa fa-th')
      ]
      if (this.publicationId) {
        tabs.push(
          new TabItem('subscriptors', 'Abonnés', 'fa fa-envelopes-bulk'),
          new TabItem('copies', 'Exemplaires', 'fa fa-book-reader')
        )
      }
      return tabs
    },
    hasAuthors() {
      return this.publicationType && this.publicationType.hasAuthors
    },
    hasArticles() {
      return this.publicationType && this.publicationType.hasArticles
    },
    copyItems() {
      return this.copies.map(this.makeCopyItem)
    },
    canEdit() {
      return this.hasPerm('publications.change_publication')
    },
    links() {
      const detail = this.activeTab && this.activeTab.selector === 'detail'
      const copies = this.activeTab && this.activeTab.selector === 'copies'
      const canExcel = !this.isLoading(this.loadingName) && (!detail)
      const canAddCopy = !this.isLoading(this.loadingName) && copies
      const links = [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.loadingName)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (!canExcel) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      if (canAddCopy && this.hasPerm('publications.add_publicationcopy')) {
        links.push(
          {
            id: 3,
            label: 'Nouvel exemplaire',
            callback: () => {
              this.$bvModal.show('bv-add-publication-copy')
            },
            icon: 'fa fa-plus',
            cssClass: 'btn-primary',
          }
        )
      }
      return links
    },
    deleteCopyText() {
      let text = 'Êtes-vous sûr de supprimer l\'exemplaire'
      if (this.selectedCopy) {
        text += ' de ' + this.selectedCopy.entity.name
        text += ' du ' + dateToString(this.selectedCopy.createdOn, 'DD/MM/YYYY') + '?'
      }
      return text
    },
    isSaveDisabled() {
      if (!this.publicationType) {
        return true
      }
      if (!this.publicationTitle) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.activeTab = this.tabs[0]
    this.copyColumns = this.getCopyColumns()
    this.subscriptorColumns = this.getSubscriptorColumns()
    this.loadPublication()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    getCopyColumns() {
      const cols = [
        {
          name: 'entityName',
          label: 'Nom',
          onClick: item => {
            this.showEntitySidebar(item.entity)
          },
          isLink: item => {
            return (item.entity.id)
          },
        },
        {
          name: 'createdOn',
          label: 'le',
          dateFormat: 'LL',
        },
        {
          name: 'type',
          label: 'Type',
        },
        {
          name: 'sending',
          label: 'envoi',
        },
        {
          name: 'sentOn',
          label: 'envoyé le',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'quantity',
          label: 'Quantité',
          number: true,
          colFooterSum: true,
        },
        {
          name: 'unitPrice',
          label: 'Prix unitaire',
          currency: true,
        },
        {
          name: 'discount',
          label: 'Réduction',
          currency: true,
        },
        {
          name: 'sendingPrice',
          label: 'Prix envoi',
          currency: true,
        },
        {
          name: 'totalPrice',
          label: 'Prix total',
          currency: true,
          colFooterSum: true,
        },
        {
          name: 'invoicing',
          label: '',
          hideFilter: false,
          isLink: item => {
            return true
          },
          linkUrl: item => {
            if (item.invoice) {
              return router.resolve(
                { name: 'invoice-detail', params: { invoiceId: '' + item.invoice.id, }, }
              ).href
            } else {
              return router.resolve(
                { name: 'families-detail', params: { entityId: '' + item.entity.id, }, query: { tab: 'invoices', }, }
              ).href
            }
          },
          contentCallback: (col, item) => {
            if (item.invoice) {
              return '<span class="badge ' + item.invoice.getStyle() + '">' + item.invoicing + '</span>'
            } else {
              return '<span class="badge todo-invoice">' + item.invoicing + '</span>'
            }
          },
        },
        {
          name: 'pay',
          label: '',
          hideFilter: true,
          onClick: item => {
            if (item.pay) {
              this.payInvoice(item)
            }
          },
          isLink: item => {
            return item.pay
          },
          contentCallback: (col, item) => {
            if (item.pay) {
              return '<span class="btn btn-secondary btn-sm">' +
                '<i class="fa fa-money-bill")></i>' +
                '</span>'
            }
            return ''
          },
        }
      ]
      if (this.hasPerm('publications.delete_publicationcopy')) {
        cols.push(
          {
            name: 'delete',
            label: '  ',
            hideFilter: true,
            alignRight: true,
            maxWidth: '25px',
            isLink: item => {
              return (item)
            },
            onClick: item => {
              this.onDeleteCopy(item)
            },
          }
        )
      }
      return cols
    },
    getSubscriptorColumns() {
      return [
        {
          name: 'entityName',
          label: 'Nom',
          onClick: item => {
            this.showEntitySidebar(item.entity)
          },
          isLink: item => {
            return (item.entity.id)
          },
        },
        {
          name: 'address',
          label: 'Adresse',
        },
        {
          name: 'zipCode',
          label: 'CP',
        },
        {
          name: 'city',
          label: 'Ville',
        },
        {
          name: 'createdOn',
          label: 'le',
          dateFormat: 'LL',
        },
        {
          name: 'sending',
          label: 'envoi',
        },
        {
          name: 'sentOn',
          label: 'envoyé le',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'quantity',
          label: 'Quantité',
          number: true,
          colFooterSum: true,
        }
      ]
    },
    makeCopyItem(copy) {
      let invoice = null
      let invoicing = null
      let pay = 0
      if ((!copy.schoolYear.isArchived) && copy.sale) {
        invoice = copy.sale.invoice
        if (invoice) {
          pay = invoice.toBePaidPrice()
        }
        invoicing = invoice ? invoice.status : 'à facturer'
      }
      return {
        id: copy.id,
        entityName: copy.entity.name,
        entity: copy.entity,
        address: copy.entity.addressText(),
        zipCode: copy.entity.zipCode,
        city: copy.entity.city.name,
        createdOn: copy.createdOn,
        sentOn: copy.sentOn | null,
        totalPrice: copy.totalPrice,
        unitPrice: copy.unitPrice,
        discount: copy.discount,
        sendingPrice: copy.discount,
        sending: copy.sending ? 'Oui' : '',
        type: copy.deposit ? 'Dépôt' : '',
        quantity: copy.quantity,
        delete: '<i class="fas fa-trash"></i>',
        copy: copy,
        invoice: invoice,
        invoicing: invoicing,
        pay: pay,
      }
    },
    async init() {
      if (this.publication) {
        this.publicationType = this.publication.publicationType
        this.publicationTypes = [this.publicationType]
        if (this.publication.publishedOn) {
          this.publishedOn = dateToString(this.publication.publishedOn, 'YYYY-MM-DD')
        } else {
          this.publishedOn = ''
        }
        this.publicationNumber = this.publication.number
        this.articles = [].concat(this.publication.articles)
        this.authors = [].concat(this.publication.authors)
        this.publicationTitle = this.publication.title
        this.price = this.publication.price
        this.sendingPrice = this.publication.sendingPrice
        this.sendingPrice = this.publication.sendingPrice
        this.fabPrice = this.publication.fabPrice
        this.authorPrice = this.publication.authorPrice
        this.depositPrice = this.publication.depositPrice
        this.isAvailable = this.publication.isAvailable
        this.pagesCount = this.publication.pagesCount
        this.notes = this.publication.notes
        this.editMode = false
      } else {
        this.publishedOn = ''
        this.publicationTitle = ''
        this.publicationNumber = null
        if (this.publicationTypes.length === 1) {
          this.publicationType = this.publicationTypes[0]
        }
        this.price = null
        this.sendingPrice = null
        this.fabPrice = null
        this.authorPrice = null
        this.depositPrice = null
        this.isAvailable = true
        this.pagesCount = 0
        this.notes = ''
        this.editMode = true
      }
    },
    async loadPublicationTypes() {
      this.startLoading(this.loadingName)
      try {
        let url = '/publications/api/publication-types/'
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        this.publicationTypes = resp.data.map(makePublicationType).filter(elt => elt.isPublication)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadPublication() {
      if (this.publicationId) {
        this.startLoading(this.loadingName)
        try {
          let url = '/publications/api/publications/' + this.publicationId + '/'
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.publication = makePublication(resp.data)
          await this.init()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      } else {
        await this.loadPublicationTypes()
        await this.init()
      }
    },
    async loadCopies(subscriptor) {
      if (this.publicationId) {
        this.copies = []
        this.startLoading(this.loadingName)
        try {
          let url = '/publications/api/publication-copies/' + this.publicationId + '/'
          if (subscriptor) {
            url += '?subscriptor=1'
          }
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.copies = resp.data.map(makePublicationCopy)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    addArticle() {
      this.newArticlesCounter = this.newArticlesCounter - 1
      this.articles = this.articles.concat(
        [
          makePublicationArticle(
            { id: this.newArticlesCounter, }
          )
        ]
      )
    },
    deleteArticle(article) {
      const index = this.articles.map(elt => elt.id).indexOf(article.id)
      if (index >= 0) {
        this.articles.splice(index, 1)
        this.articles = this.articles.concat([])
      }
    },
    deleteArticleAuthor(article, author) {
      const index = article.authors.map(elt => elt.id).indexOf(author.id)
      if (index >= 0) {
        article.authors.splice(index, 1)
        article.authors = article.authors.concat([])
      }
    },
    deleteAuthor(author) {
      const index = this.authors.map(elt => elt.id).indexOf(author.id)
      if (index >= 0) {
        this.authors.splice(index, 1)
        this.authors = this.authors.concat([])
      }
    },
    deleteArticleKeyword(article, keyword) {
      const index = article.keywords.map(elt => elt.id).indexOf(keyword.id)
      if (index >= 0) {
        article.keywords.splice(index, 1)
        article.keywords = article.keywords.concat([])
      }
    },
    addArticleKeyword(article) {
      this.newKeywordsCounter = this.newKeywordsCounter - 1
      article.keywords.push({ id: this.newKeywordsCounter, })
    },
    addArticleAuthor(article) {
      this.selectedArticle = article
      this.$bvModal.show('bv-modal-add-author')
    },
    addAuthor() {
      this.selectedArticle = null
      this.$bvModal.show('bv-modal-add-author')
    },
    onAuthorAdded(event) {
      this.newAuthorsCounter = this.newAuthorsCounter - 1
      const individual = event.individual
      const authorsList = this.selectedArticle ? this.selectedArticle.authors : this.authors
      const exists = authorsList.map(
        elt => elt.individual.id
      ).indexOf(individual.id) >= 0
      if (!exists) {
        authorsList.push(
          { id: this.newAuthorsCounter, individual: individual, }
        )
      } else {
        this.addWarning('' + individual.firstAndLastName() + ' est déjà listé comme auteur de cet article')
      }
    },
    onEdit() {
      this.editMode = true
    },
    onCancel() {
      if (this.publication) {
        this.editMode = false
      } else {
        this.$router.push({ name: 'publications-home', })
      }
    },
    async onSave() {
      if (!this.isSaveDisabled) {
        this.startLoading(this.loadingName)
        let url = '/publications/api/publications/'
        let method = 'post'
        if (this.publication) {
          url += this.publicationId + '/'
          method = 'patch'
        }
        const authors = this.hasAuthors ? this.authors : []
        const articles = this.hasArticles ? this.articles : []
        const data = {
          'publication_type': this.publicationType.id,
          'title': this.publicationTitle,
          'number': this.publicationNumber,
          'published_on': this.publishedOn ? dateToString(this.publishedOn, 'YYYY-MM-DD') : null,
          'authors_individuals': authors.map(elt => elt.individual.id),
          'articles': articles.map(
            artData => {
              return {
                'title': artData.title,
                'keywords': artData.keywords.map(keyword => keyword.keyword).filter(word => !!word),
                'authors_individuals': artData.authors.map(elt => elt.individual.id),
                'notes': artData.notes,
                'comments': artData.comments,
              }
            }
          ),
          'price': this.price,
          'sending_price': this.sendingPrice,
          'author_price': this.authorPrice,
          'is_available': this.isAvailable,
          'fab_price': this.fabPrice,
          'deposit_price': this.depositPrice,
          'notes': this.notes,
        }
        const backendApi = new BackendApi(method, url)
        try {
          const resp = await backendApi.callApi(data)
          const publication = makePublication(resp.data)
          if (this.publicationId) {
            this.publication = publication
            await this.init()
          } else {
            await router.push(
              { name: 'publications-edit', params: { 'publicationId': publication.id, }, }
            )
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    onTabChanged(tab) {
      this.activeTab = tab
      if (tab.selector === 'copies') {
        this.loadCopies(0)
      }
      if (tab.selector === 'subscriptors') {
        this.loadCopies(1)
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = slugify(this.activeTab.label + '-' + this.publication.fullName())
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = slugify(this.activeTab.label + '-' + this.publication.fullName())
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onDeleteCopy(item) {
      this.selectedCopy = item.copy
      this.$bvModal.show('bv-confirm-modal:delete-publication-copy')
    },
    async onConfirmDelete(event) {
      const copy = event.object
      try {
        const url = '/publications/api/delete-publication-copy/' + copy.id + '/'
        const backendApi = new BackendApi('delete', url)
        await backendApi.callApi()
        await this.addSuccess('L\'exemplaire a été supprimé')
        await this.loadCopies(0)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    payInvoice(item) {
      this.paymentEntity = item.entity
      this.paymentInvoice = item.invoice
      this.$nextTick(
        () => {
          this.$bvModal.show('pay-copy-modal')
        }
      )
    },
  },
}
</script>

<style scoped lang="less">
.articles-header {
  padding:  10px;
  background: #ccc;
}
.article-line {
  margin-bottom: 20px;
  padding: 10px;
  background: #eee;
}
.tab-content {
  margin-top: 5px;
}
</style>
